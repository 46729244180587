import React from 'react'
import * as Styled from './SectionImagesTwoColumns.styles'
import { Column, Row } from '../../layout/Grid'
import DoubleTitle from '../DoubleTitle'
import Button from '../Button'

const SectionImagesTwoColumns = ({
  title,
  smallTitle,
  lead,
  page,
  imageLeft,
  imageRight,
}) => {
  return (
    <Styled.Section>
      <Row alignBottom>
        <Column xs={12} lg={5}>
          <DoubleTitle
            smallTitle={smallTitle}
            title={title}
            border="addon"
            noBorderLarge
          />
          {imageLeft}
        </Column>
        <Column xs={12} lg={7} xl={6}>
          {imageRight}
          <p className="lead">{lead}</p>
        </Column>
      </Row>
      {page && (
        <Row column>
          <Button primary page={page}>
            Czytaj więcej
          </Button>
        </Row>
      )}
    </Styled.Section>
  )
}

export default SectionImagesTwoColumns
