import styled, { css } from 'styled-components'
import { BgImage } from 'gbimage-bridge'

const Section = styled(BgImage)`
  position: relative;
  z-index: 1;
  padding: 40px 0 60px;

  @media (min-width: ${props => props.theme.breakpoints.lgUp}) {
    padding: 80px 0;
  }

  @media (min-width: ${props => props.theme.breakpoints.xxlUp}) {
    padding: 120px 0;
  }

  * {
    color: #fff;
  }

  a {
    margin-top: 16px;

    @media (min-width: ${props => props.theme.breakpoints.lgUp}) {
      margin-top: 32px;
    }
  }
`

const ImgWrapper = styled.div`
  position: relative;
  transform: scale(0.7);
  transition: 1.5s transform ease-in-out;

  .gatsby-image-wrapper + .gatsby-image-wrapper {
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
    transition: 0.2s opacity ease-in-out 1.5s;
  }

  ${props =>
    props.inView &&
    css`
      transform: scale(1);

      .gatsby-image-wrapper + .gatsby-image-wrapper {
        opacity: 1;
      }
    `}
`

export { Section, ImgWrapper }
