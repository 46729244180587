import styled, { css } from 'styled-components'

const Section = styled.section`
  position: relative;
  padding: 348px 0 60px;

  @media (min-width: ${props => props.theme.breakpoints.lgUp}) {
    padding-top: 60px;
  }

  .lead {
    margin-bottom: 24px;

    @media (min-width: ${props => props.theme.breakpoints.lgUp}) {
      margin: 40px 0 32px;
    }
  }

  .img-left {
    width: 80%;

    @media (max-width: ${props => props.theme.breakpoints.lg}) {
      display: none;
    }
  }

  .img-right {
    @media (max-width: ${props => props.theme.breakpoints.lg}) {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      height: 300px;
    }
  }

  a {
    @media (min-width: ${props => props.theme.breakpoints.lgUp}) {
      margin-top: 60px;
    }
  }
`

export { Section }
