import styled, { css } from 'styled-components'

const Section = styled.section`
  position: relative;
  padding: 50px 0 32px;

  @media (min-width: ${props => props.theme.breakpoints.lgUp}) {
    padding: 120px 0;
  }
  @media (min-width: ${props => props.theme.breakpoints.xxlUp}) {
    padding: 160px 0;
  }

  .lead + a {
    margin-top: 16px;

    @media (min-width: ${props => props.theme.breakpoints.lgUp}) {
      margin-top: 32px;
    }
  }

  .swiper {
    @media (max-width: ${props => props.theme.breakpoints.lg}) {
      overflow: visible;
    }
    @media (min-width: ${props => props.theme.breakpoints.lgUp}) {
      position: absolute;
      right: 50%;
      width: 50%;
      left: 0;
      top: calc(50% - 280px);
    }
  }

  .swiper-slide {
    width: 230px;
    height: 230px;

    @media (min-width: ${props => props.theme.breakpoints.lgUp}) {
      width: 512px;
      height: 512px;
    }

    a {
      &:not(:hover) {
        .gatsby-image-wrapper {
          opacity: 0.5;
        }
      }

      .gatsby-image-wrapper {
        object-fit: cover;
        object-position: center;
        width: 100%;
        height: 100%;
        transition: 0.2s opacity;
      }
    }
  }
`

const SwiperActionWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 40px 0;

  @media (min-width: ${props => props.theme.breakpoints.lgUp}) {
    position: absolute;
    right: 50%;
    top: calc(50% + 240px);
    width: 512px;
    z-index: 2;
  }

  button {
    margin-right: 16px;
  }
`

const SwiperPagination = styled.span`
  display: inline-block;
  font-size: 20px;
  font-weight: 500;
  color: ${props => props.theme.colors.dark};
  letter-spacing: 0.1em;
`

const SwiperPaginationCurrent = styled.div`
  display: inline-block;
  position: relative;
  padding-right: 48px;

  &:after {
    content: '';
    width: 48px;
    height: 2px;
    background-color: ${props => props.theme.colors.primary};
    position: absolute;
    right: 0;
    bottom: 6px;
  }
`

export {
  Section,
  SwiperActionWrapper,
  SwiperPagination,
  SwiperPaginationCurrent,
}
