import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { getImage, StaticImage } from 'gatsby-plugin-image'
import { useInView } from 'react-intersection-observer'
import * as Styled from './SectionGlobe.styles'
import { Column, Row } from '../../layout/Grid'
import DoubleTitle from '../DoubleTitle'
import Button from '../Button'

const SectionBgImageText = () => {
  const [customRef, inView] = useInView()

  const { sectionBg } = useStaticQuery(
    graphql`
      query {
        sectionBg: file(relativePath: { eq: "home/planeta-bg.jpg" }) {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    `
  )

  return (
    <Styled.Section Tag="section" image={getImage(sectionBg)}>
      <Row alignMiddle>
        <Column xs={12} lg={7} largeOrder={2}>
          <Styled.ImgWrapper ref={customRef} inView={inView}>
            <StaticImage src="../../images/home/globe.png" alt="" />
            <StaticImage src="../../images/home/globe-addon.png" alt="" />
          </Styled.ImgWrapper>
        </Column>
        <Column xs={12} lg={5}>
          <DoubleTitle
            smallTitle="Zasięg terytorialny"
            title={<>Dostarczymy ładunek niemal wszędzie!</>}
            border="white"
          />
          <p className="lead">
            Celem Nordic Logistics jest skuteczna, kompleksowa organizacja
            transportu drogowego. Z&nbsp;ładunkami naszych Klientów docieramy do
            każdego zakątka Europy, od Skandynawii po Turcję i&nbsp;Półwysep
            Iberyjski. Rozwijamy także kierunki wschodnie - Rosja, Gruzja,
            Białoruś, Ukraina.
          </p>
          <Button primary page="/uslugi/transport-miedzynarodowy">
            Czytaj więcej
          </Button>
        </Column>
      </Row>
    </Styled.Section>
  )
}

export default SectionBgImageText
