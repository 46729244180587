import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { getImage, StaticImage } from 'gatsby-plugin-image'
import Layout from '../layout/Layout'
import config from '../config/theme'
import SectionHeader from '../components/SectionHeader'
import SectionOfferSlider from '../components/SectionOfferSlider'
import SectionIconsSlider from '../components/SectionIconsSlider'
import SectionImagesTwoColumns from '../components/SectionImagesTwoColumns'
import SectionBgImageText from '../components/SectionBgImageText'
import SectionDocs from '../components/SectionDocs'
import SectionGlobe from '../components/SectionGlobe'
import IconSkarbonka from '../images/icons/skarbonka.svg'
import IconFlota from '../images/icons/flota.svg'
import IconDokument from '../images/icons/dokument.svg'
import Icon24 from '../images/icons/calydzien.svg'
import IconPracownik from '../images/icons/pracownik.svg'
import IconKalendarz from '../images/icons/kalendarz.svg'

export default function Home() {
  const { headerBgMobile, headerBg, sectionBgMobile, sectionBg } =
    useStaticQuery(
      graphql`
        query {
          headerBgMobile: file(
            relativePath: { eq: "home/home-bg-mobile.jpg" }
          ) {
            childImageSharp {
              gatsbyImageData
            }
          }
          headerBg: file(relativePath: { eq: "home/home-bg.jpg" }) {
            childImageSharp {
              gatsbyImageData
            }
          }
          sectionBgMobile: file(
            relativePath: { eq: "home/partner-bg-mobile.jpg" }
          ) {
            childImageSharp {
              gatsbyImageData
            }
          }
          sectionBg: file(relativePath: { eq: "home/partner-bg.jpg" }) {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      `
    )

  const headerImages = [
    { ...getImage(headerBgMobile) },
    {
      ...getImage(headerBg),
      media: `(min-width: ${config.base.breakpoints.lgUp})`,
    },
  ]

  const sectionImages = [
    { ...getImage(sectionBgMobile) },
    {
      ...getImage(sectionBg),
      media: `(min-width: ${config.base.breakpoints.lgUp})`,
    },
  ]

  return (
    <Layout
      metaTitle="Transport drogowy"
      metaDescription="Jesteśmy firmą, która od wielu lat zapewnia Klientom krajowy i międzynarodowy transport drogowy w standardowym i specjalistycznym zakresie."
      slug="/"
      before="Nordic"
      after="Logistics"
    >
      <SectionHeader
        smallTitle="O nas"
        title={
          <>
            Nordic Logistics <br />
            Transport drogowy
          </>
        }
        titleVariant="h2"
        titleBorder="white"
        lead="Jesteśmy firmą, która od wielu lat zapewnia Klientom krajowy i&nbsp;międzynarodowy transport drogowy w standardowym i&nbsp;specjalistycznym zakresie. Spełniamy wszystkie niezbędne wymogi i&nbsp;dopełniamy koniecznych formalności, dzięki czemu Klienci zyskują pewność, że są obsługiwani przez fachowców w&nbsp;branży."
        scroll="#czytaj-wiecej"
        bgImage={headerImages}
        nordicLogistics
      />
      <SectionOfferSlider
        smallTitle="Usługi"
        title={<>Transport według Twoich potrzeb</>}
        content={
          <>
            <p className="lead">
              Korzystamy z profesjonalnych rozwiązań logistycznych. Realizujemy
              transporty drogowe ładunków różnego rodzaju
              i&nbsp;we&nbsp;wszystkich gabarytach – od 1&nbsp;palety po ładunki
              całopojazdowe.
            </p>
            <p className="lead">
              Jeśli interesuje Cię kompleksowo zorganizowany transport
              międzynarodowy lub krajowy, Nordic Logistics jest najlepszym
              rozwiązaniem!
            </p>
          </>
        }
        button
        items={[
          {
            page: 'uslugi/transport-krajowy',
            name: 'Transport krajowy',
            image: (
              <>
                <StaticImage
                  src="../images/nasze-uslugi/transport-krajowy.jpg"
                  alt="Transport krajowy"
                  className="show-for-large"
                />
                <StaticImage
                  src="../images/nasze-uslugi/transport-krajowy-mobile.jpg"
                  alt="Transport krajowy"
                  className="hide-for-large"
                />
              </>
            ),
          },
          {
            page: 'uslugi/transport-miedzynarodowy',
            name: 'Transport międzynarodowy',
            image: (
              <>
                <StaticImage
                  src="../images/nasze-uslugi/transport-miedzynarodowy.jpg"
                  alt="Transport międzynarodowy"
                  className="show-for-large"
                />
                <StaticImage
                  src="../images/nasze-uslugi/transport-miedzynarodowy-mobile.jpg"
                  alt="Transport międzynarodowy"
                  className="hide-for-large"
                />
              </>
            ),
          },
          {
            page: 'uslugi/transport-ladunkow-neutralnych',
            name: 'Transport towarów neutralnych',
            image: (
              <>
                <StaticImage
                  src="../images/nasze-uslugi/transport-towarow-neutralnych.jpg"
                  alt="Transport towarów neutralnych"
                  className="show-for-large"
                />
                <StaticImage
                  src="../images/nasze-uslugi/transport-towarow-neutralnych-mobile.jpg"
                  alt="Transport towarów neutralnych"
                  className="hide-for-large"
                />
              </>
            ),
          },
          {
            page: 'uslugi/transport-ponadgabarytowy',
            name: 'Transport ponadgabarytowy',
            image: (
              <>
                <StaticImage
                  src="../images/nasze-uslugi/transport-ponadgabarytowy.jpg"
                  alt="Transport ponadgabarytowy"
                  className="show-for-large"
                />
                <StaticImage
                  src="../images/nasze-uslugi/transport-ponadgabarytowy-mobile.jpg"
                  alt="Transport ponadgabarytowy"
                  className="hide-for-large"
                />
              </>
            ),
          },
          {
            page: 'uslugi/transport-materialow-niebezpiecznych',
            name: 'Transport materiałów niebezpiecznych',
            image: (
              <>
                <StaticImage
                  src="../images/nasze-uslugi/transport-materialow-niebezpiecznych.jpg"
                  alt="Transport materiałów niebezpiecznych"
                  className="show-for-large"
                />
                <StaticImage
                  src="../images/nasze-uslugi/transport-materialow-niebezpiecznych-mobile.jpg"
                  alt="Transport materiałów niebezpiecznych"
                  className="hide-for-large"
                />
              </>
            ),
          },
          {
            page: 'uslugi/transport-w-temperaturze-kontrolowanej',
            name: 'Transport w temperaturze kontrolowanej',
            image: (
              <>
                <StaticImage
                  src="../images/nasze-uslugi/transport-w-temperaturze-kontrolowanej.jpg"
                  alt="Transport w temperaturze kontrolowanej"
                  className="show-for-large"
                />
                <StaticImage
                  src="../images/nasze-uslugi/transport-w-temperaturze-kontrolowanej-mobile.jpg"
                  alt="Transport w temperaturze kontrolowanej"
                  className="hide-for-large"
                />
              </>
            ),
          },
          {
            page: 'uslugi/transport-ladunkow-sypkich',
            name: 'Transport ładunków sypkich',
            image: (
              <>
                <StaticImage
                  src="../images/nasze-uslugi/transport-ladunkow-sypkich.jpg"
                  alt="Transport ładunków sypkich"
                  className="show-for-large"
                />
                <StaticImage
                  src="../images/nasze-uslugi/transport-ladunkow-sypkich-mobile.jpg"
                  alt="Transport ładunków sypkich"
                  className="hide-for-large"
                />
              </>
            ),
          },
        ]}
      />
      <SectionBgImageText
        smallTitle="Flota"
        title="Sprawdzony partner w&nbsp;transporcie drogowym"
        lead={
          <>
            Dążymy do doskonałości, dlatego wykorzystywana przez nas flota
            spełniała wszelkie normy bezpieczeństwa, nie tylko w kwestii stanu
            technicznego pojazdów, ale również jej wyposażenia.
          </>
        }
        content={
          <ul className="list">
            <li>Ciągniki siodłowe z naczepami</li>
            <li>Samochody ciężarowe (solówki)</li>
            <li>Samochody ciężarowe + przyczepy (zestawy)</li>
            <li>Samochody dostawcze (busy)</li>
            <li>Ciągniki siodłowe z naczepami specjalistycznymi</li>
            <li>Wywrotki, cysterny, silosy</li>
          </ul>
        }
        narrowContent
        bgImage={sectionImages}
        page="/flota"
        pageText="Zobacz flotę"
      />
      <SectionIconsSlider
        smallTitle="Nordic Logistics"
        title={
          <>
            Nasze mocne <br />
            strony
          </>
        }
        content={
          <p className="lead">
            Dzięki wieloletniemu doświadczeniu i&nbsp;wdrażaniu nowych
            rozwiązań, opracowaliśmy metody, poprzez które komunikacja
            z&nbsp;Klientami jest płynna, a&nbsp;jakość świadczonych przez nas
            usług stawia wysoko poprzeczkę naszym konkurentom, także
            z&nbsp;rynków zagranicznych.
          </p>
        }
        button
        items={[
          {
            icon: <IconFlota />,
            title: (
              <>
                Dobór sprawdzonych <br />
                oraz rzetelnych <br />
                podwykonawców
              </>
            ),
            content: (
              <>
                Gwarancją naszego sukcesu są pewni i&nbsp;profesjonalni
                Partnerzy działający na&nbsp;nasze zlecenia.
              </>
            ),
          },
          {
            icon: <IconDokument />,
            title: (
              <>
                Jasne warunki <br />
                współpracy i minimum <br />
                formalności
              </>
            ),
            content: (
              <>
                Wiemy, jak ważny jest czas. Działamy w&nbsp;sposób ułatwiający
                Klientom zlecanie usług.
              </>
            ),
          },
          {
            icon: <IconSkarbonka />,
            title: (
              <>
                Minimalizowanie <br />
                kosztów transportu <br />
                dla Klienta
              </>
            ),
            content: (
              <>
                Zawsze informujemy Klientów o&nbsp;wszystkich kosztach, które
                ograniczamy do&nbsp;minimum.
              </>
            ),
          },
          {
            icon: <Icon24 />,
            title: (
              <>
                Pełna <br />
                dyspozycyjność
              </>
            ),
            content: (
              <>
                Nasz zespół służy Państwu pomocą przez cały czas, w&nbsp;dogodny
                dla&nbsp;Państwa sposób.
              </>
            ),
          },
          {
            icon: <IconPracownik />,
            title: (
              <>
                Rozwój i&nbsp;wysoki <br />
                poziom motywacji <br />
                kadry pracowników
              </>
            ),
            content: (
              <>
                Poszerzamy nasze horyzonty, dzięki czemu nie ma dla nas rzeczy
                niemożliwych.
              </>
            ),
          },
          {
            icon: <IconKalendarz />,
            title: (
              <>
                Terminowość <br />
                i bezpieczeństwo <br />
                dostaw
              </>
            ),
            content: (
              <>
                Dbamy o kontrolę procesów transportowych, co&nbsp;skutkuje
                wysokim poziomem jakości usług.
              </>
            ),
          },
        ]}
      />
      <SectionDocs />
      <SectionImagesTwoColumns
        smallTitle="ZESPÓŁ PROFESJONALISTÓW"
        title={
          <>
            Zaufaj <br />
            naszemu <br />
            doświadczeniu
          </>
        }
        lead={
          <>
            Wyróżnia nas pełna dyspozycyjność - zarówno wtedy, kiedy chcesz
            dowiedzieć się, jak zorganizować przewóz danego towaru, jak
            i&nbsp;od momentu przyjęcia Twojego zamówienia aż do zakończenia
            jego realizacji. W każdej chwili możesz skontrolować, na jakim
            etapie jest przesyłka i&nbsp;czy ma zapewnione właściwe warunki
            przewozu.
          </>
        }
        page="/o-nas"
        imageLeft={
          <StaticImage
            src="../images/home/zaufaj1.jpg"
            alt=""
            className="img-left"
          />
        }
        imageRight={
          <StaticImage
            src="../images/home/zaufaj2.jpg"
            alt=""
            className="img-right"
          />
        }
      />
      <SectionGlobe />
    </Layout>
  )
}
