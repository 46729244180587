import React, { useRef, useState } from 'react'
import { Link } from 'gatsby'
import SwiperCore, { Navigation, Pagination } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Row, Column } from '../../layout/Grid'
import DoubleTitle from '../DoubleTitle'
import Button from '../Button'
import * as Styled from './SectionOfferSlider.styles'
import * as StyledSwiper from '../Swiper/Swiper.styles'

// Install modules
SwiperCore.use([Navigation, Pagination])

const SectionOfferSlider = ({ title, smallTitle, content, items }) => {
  const swiper = useRef()
  const [activeSlide, setActiveSlide] = useState(6)

  return (
    <Styled.Section id="czytaj-wiecej">
      <Row alignMiddle alignJustify>
        <Column xs={12} lg={6}>
          <Swiper
            spaceBetween={20}
            slidesPerView="auto"
            noSwiping
            breakpoints={{
              1024: {
                spaceBetween: 40,
              },
            }}
            wrapperTag="ul"
            initialSlide={activeSlide}
            onSlideChange={el => setActiveSlide(el.activeIndex)}
            ref={swiper}
          >
            {items.map(item => (
              <SwiperSlide tag="li" key={item.page}>
                <Link to={item.page}>
                  <span className="sr-only">{item.name}</span>
                  {item.image}
                </Link>
              </SwiperSlide>
            ))}
          </Swiper>
          <Styled.SwiperActionWrapper>
            <span>
              <StyledSwiper.CustomPrevButton
                onClick={() => swiper.current.swiper.slidePrev()}
                type="button"
                aria-label="Poprzedni slajd"
              />
              <StyledSwiper.CustomNextButton
                onClick={() => swiper.current.swiper.slideNext()}
                type="button"
                aria-label="Następny slajd"
              />
            </span>
            <Styled.SwiperPagination>
              <Styled.SwiperPaginationCurrent>
                0{activeSlide + 1}&nbsp;
              </Styled.SwiperPaginationCurrent>{' '}
              &nbsp;0{items.length}
            </Styled.SwiperPagination>
          </Styled.SwiperActionWrapper>
        </Column>
        <Column xs={12} lg={6} xl={5}>
          <DoubleTitle smallTitle={smallTitle} title={title} border="addon" />
          {content}
          <Button page="/uslugi" primary>
            Zobacz ofertę
          </Button>
        </Column>
      </Row>
    </Styled.Section>
  )
}

export default SectionOfferSlider
