import styled, { css } from 'styled-components'

const Section = styled.section`
  position: relative;
  padding: 24px 0;

  @media (min-width: ${props => props.theme.breakpoints.lgUp}) {
    padding: 40px 0;
  }
  @media (min-width: ${props => props.theme.breakpoints.xxlUp}) {
    padding: 60px 0;
  }
`

const DocsWrapper = styled.ul`
  display: flex;
  flex-flow: row wrap;
  margin: 16px 0;

  @media (min-width: ${props => props.theme.breakpoints.lgUp}) {
    justify-content: center;
  }
`

const DocWrapper = styled.li`
  flex: 0 0 50%;
  max-width: 50%;
  text-align: center;

  @media (min-width: ${props => props.theme.breakpoints.lgUp}) {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .lead {
    text-transform: uppercase;
  }

  svg {
    height: 64px;
    width: auto;
    transition: 0.2s transform;

    @media (min-width: ${props => props.theme.breakpoints.lgUp}) {
      height: 100px;
    }
  }
`

const ImgWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100px;

  @media (min-width: ${props => props.theme.breakpoints.lgUp}) {
    margin-bottom: 24px;
  }

  img {
    height: 100px;
    width: auto;
  }
`

export { Section, DocsWrapper, DocWrapper, ImgWrapper }
